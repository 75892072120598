.LabelBox {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LabelText {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.BubbleHeader {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.BubbleBulletsText {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}