/* Master Container */

.Card-Composite-Preview {
    position: relative;
    width: 450px;
    height: 630px;
    display: flex;
}

/* Name, Types, Cost - Adaptive Text */

.CardPreviewText-Name-DeckCards {
    z-index: 1;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    width: 270px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.2 !important;
}
.CardPreviewText-Name-NonDeckCards {
    z-index: 1;
    position: absolute;
    top: 8.4%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    width: 330px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: -0.5px !important;
    line-height: 1.2 !important;
}

.CardPreviewBox-Types {
    z-index: 1;
    position: absolute;
    bottom: 7.2%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 224px;
    height: 22px;
    text-align: center;
    overflow: hidden; 
}
.CardPreviewText-Types {
    z-index: 1;
    position: absolute;
    color: black;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    bottom: 0%;
}

.CardPreviewBox-Cost {
    z-index: 1;
    position: absolute;
    top: 6.8%;
    right: 8.7%;
    width: 28px;
    height: 23px;
}
.CardPreviewText-Cost {
    z-index: 1;
    position: absolute;
    color: black;
    width: 100%;
    white-space: nowrap;
    font-size: 17px !important;
    text-align: center;
    vertical-align: top;
}

/* Power/Int & Def/Hp - Adaptive Text */

.CardPreviewBox-PowerIntellect {
    z-index: 1;
    position: absolute;
    width: 22px;
    height: 22px;
}
.CardPreviewBox-PowerIntellect-OldFrame {
    bottom: 5.3%;
    left: 16.5%;
}
.CardPreviewBox-PowerIntellect-NewFrame {
    bottom: 7%;
    left: 14.5%;
}
.CardPreviewText-PowerIntellect {
    z-index: 1;
    position: absolute;
    width: 100%;
    color: black;
    font-size: 16px !important;
    text-align: center;
    vertical-align: middle;
}

.CardPreviewBox-DefenseHealth {
    z-index: 1;
    position: absolute;
    width: 22px;
    height: 22px;
}
.CardPreviewBox-DefenseHealth-OldFrame {
    bottom: 5.3%;
    right: 16.5%;
}
.CardPreviewBox-DefenseHealth-NewFrame {
    bottom: 7%;
    right: 14.5%;
}
.CardPreviewText-DefenseHealth {
    z-index: 1;
    position: absolute;
    width: 100%;
    color: black;
    font-size: 16px !important;
    text-align: center;
    vertical-align: middle;
}

/* Effect Text */

.CardPreviewBox-Effect-OldFrame {
    z-index: 1;
    position: absolute;
    width: 340px;
    height: 160px;
    bottom: 12%;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}
.CardPreviewBox-Effect-NewFrame {
    z-index: 1;
    position: absolute;
    width: 340px;
    height: 160px;
    bottom: 13%;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}
.CardPreviewBox-Effect-HeroFrame {
    z-index: 1;
    position: absolute;
    width: 340px;
    height: 150px;
    bottom: 12.7%;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}

.CardPreviewText-Effect-HeroFrame {
    z-index: 1;
    position: absolute;
    width: 100%;
    color: black;
    white-space: pre-line;
    text-align: left !important;
    overflow:hidden;
    font-size: 15px !important;
    line-height: 1.1 !important;
    margin-bottom: 0px !important;
    letter-spacing: 0.1px !important;
    word-spacing: 0.8px;
}
.CardPreviewText-Effect-NonheroFrame {
    z-index: 1;
    position: absolute;
    width: 100%;
    color: black;
    white-space: pre-line;
    text-align: left !important;
    overflow:hidden;
    font-size: 16px !important;
    line-height: 1.2 !important;
    margin-bottom: 0px !important;
    letter-spacing: 0.1px !important;
    word-spacing: 0.8px;
}

/* Border & Uploaded Art - Basic Images */

.BorderArt-Image {
    z-index: 0;
    width: 450px;
    height: 630px;
    position: relative;
}
  
.CardArtwork-Image {
    z-index: -2;
    width: 450px;
    height: 630px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    clip-path: inset(3%);
}
  
.CardArtwork-Image-SmallerAlt {
    z-index: -2;
    width: 385px;
    height: 385px;
    position: absolute;
    top: 6%;
    left: 50%;
    transform: translate(-50%, 0%);
}

/* Pitch - Basic Images */

.PitchColorStrip-Image {
    z-index: -2;
    position: absolute;
    width: 350px;
    height: 30px;
    left: 50%;
    top: 4.8%;
    transform: translate(-50%, -50%);
}
  
.PitchCornerBg-Image {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 12%;
    top: 8.7%;
    transform: translate(-50%, -50%);
    background-color: rgba(37, 37, 37, 1);
    border-radius: 50%;
}

.Resource-CornerIcon-1 {
    z-index: 1;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 5.25%;
    left: 10%;
}
.Resource-CornerIcon-2 {
    z-index: 1;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 8.3%;
    left: 7.7%;
}
.Resource-CornerIcon-3 {
    z-index: 1;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 8.3%;
    left: 12.6%;
}

/* Power & Defense - Adaptive Images */

.Power-Icon-OldFrame {
    z-index: 1;
    position: absolute;
    width: 38px;
    height: 38px;
    bottom: 5%;
    left: 6.5%;
}
.Power-Icon-NewFrame {
    z-index: 1;
    position: absolute;
    width: 36px;
    height: 36px;
    bottom: 6.7%;
    left: 5.75%;
}
  
.Defense-Icon-OldFrame {
    z-index: 1;
    position: absolute;
    width: 38px;
    height: 38px;
    bottom: 5%;
    right: 6.3%;
}
.Defense-Icon-NewFrame {
    z-index: 1;
    position: absolute;
    width: 36px;
    height: 36px;
    bottom: 6.7%;
    right: 5.5%;
}

/* Bottom Of Card */

.BottomOfCard-Info-Box-OldFrame {
    z-index: 1;
    position: absolute;
    bottom: 0.2%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 408px;
    height: 14px;
}
.BottomOfCard-Info-Box-NewFrame {
    z-index: 1;
    position: absolute;
    bottom: 2.4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 408px;
    height: 14px;
}

.BottomOfCard-Language {
    z-index: 1;
    position: absolute;
    bottom: 50%;
    left: 32px;
    width: 25px;
    height: 12px;
    transform: translate(0%, 50%);
    color: white;
    display: flex;
    align-items: center;
    font-size: 12px !important;
}
  
.BottomOfCard-SetCode {
    z-index: 1;
    position: absolute;
    bottom: 50%;
    left: 57px;
    width: 60px;
    height: 12px;
    transform: translate(0%, 50%);
    color: white;
    display: flex;
    align-items: center;
    font-size: 12px !important;
}
  
.BottomOfCard-Static-AdNames {
    z-index: 1;
    position: absolute;
    bottom: 50%;
    right: 6px;
    height: 12px;
    text-align: right;
    transform: translate(0%, 50%);
    color: white;
    display: flex;
    align-items: center;
    font-size: 12px !important;
}

.BottomOfCard-Rarity-Image {
    z-index: 1;
    position: absolute;
    left:6px;
    bottom: 50%;
    transform: translate(0%, 50%);
    width: 14px;
    height: 14px;
}
