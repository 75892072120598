/* Hero Banner */

.HeroBannerBox {
    width: 100%;
    height: 80vh;

    overflow: hidden;
    position: relative;
}

.HeroBannerImg {
    background-size: cover;
    background-position: center center;
}

.HeroBannerNameText {
    color: white;
}